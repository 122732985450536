.footer {
  position: fixed;
  bottom: 0;
  right: 400px;
  padding: 3px;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 2px black;
  height: 33px;
  transition: height 0.5s;
  font-size: 0.8em;
}

.footer button {
  font-size: 1em;
  border: none;
  background-color: rgba(0,0,0,0);
  color: white;
}

.footer button:focus {outline:0;}
.footer button:hover {
  cursor: pointer;
  text-decoration: underline;
}

@-moz-document url-prefix() {
  .footer {
       background-color: rgba(51,51,51,1);
  }
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  color: white;
  text-decoration: underline;
}

.footer.open {
  height: 100px
}
