body {
  touch-action: none;
}

svg.main {
  position: fixed;
  top: 0;
  left: 0;
  cursor: grab
}

svg.main .undraggable{
  cursor: auto;
  --b:1px;   /* thickness of the border */
  --c:rgba(255,255,255, 0.2);   /* color of the border */
  --w:10px;  /* width of border */
  

  border:var(--b) solid transparent; /* space for the border */
  background:
    linear-gradient(var(--c),var(--c)) top left,
    linear-gradient(var(--c),var(--c)) top left,
    linear-gradient(var(--c),var(--c)) bottom left,
    linear-gradient(var(--c),var(--c)) bottom left,
    linear-gradient(var(--c),var(--c)) top right,
    linear-gradient(var(--c),var(--c)) top right,
    linear-gradient(var(--c),var(--c)) bottom right,
    linear-gradient(var(--c),var(--c)) bottom right;
  background-size:var(--b) var(--w),var(--w) var(--b);
  background-origin:border-box;
  background-repeat:no-repeat;
}

.pin {
   transform: translate(125px, 9px) scale(0.1) rotate(35deg);
}

.pin-pinned {
   transform: translate(125px, 9px) scale(0.1) rotate(35deg);
   animation-duration: 0.5s;
   animation-name: slidein;
}

.show-more {
  transform: translate(124px, 20px) scale(0.06);
  transition: opacity 0.3s;
}

.show-more.hide {
  opacity: 0
}

.show-more.show {
  opacity: 1
}

.backdrop {
  background-color: rgba(31,31,31,0);
  backdrop-filter: blur(5px) saturate(50%);
  -webkit-backdrop-filter: blur(5px) saturate(50%);
}

@-moz-document url-prefix() {
  .backdrop {
       background-color: rgba(33,33,33,1);
  }
}

svg {
    user-select: none;
}

svg foreignObject {
    user-select: text;
    padding: 3px;/*fix drawing artefact witrh border on Brave*/
}

@keyframes slidein {
  from {
    transform: translate(125px, 9px) scale(0.8) rotate(35deg);
  }

  to {
    transform: translate(125px, 9px) scale(0.1) rotate(35deg);
  }
}