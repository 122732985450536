.details {
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  padding: 0px;
  height: 100%;
  text-align: center;
}

.details .ident-civ {
  opacity: 0.3;
  font-size: 0.4em;
}

.small-details, .small-details > * {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
}

.small-details > dt {
  display: inline-block;
  margin-right: 5px;
}

.small-details > dd {
  display: inline-block;
  margin-right: 10px;
}

.big-details {
  text-align: left;
  padding: 10px
}

.big-details > dt {
  color: #777777;
  font-size: 0.8em;
}

.desaturated {
  filter: saturate(0%);
}

.social-bar {
    /* position: absolute;
    top: 15px;
    right: 5px; */
    display: flex;
    font-size: 1.2em;
    filter:drop-shadow;
    /*text-shadow: 1px 1px 2px pink;*/
    color: black;
    font-size: 1.8em;
}
